.modal {
  width: 50vw !important;
}

.content {
  width: 100%;
  height: 82vh;
  overflow: scroll;
  position: relative;
}

.loadingCover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff63;
  z-index: 9999;
}

.btn {
  color: rgb(80, 129, 190);
  background-color: transparent;
  border: 1px solid rgba(80, 129, 190, 0.8);
  border-radius: 2px;
  height: 38px;
  font-size: 0.9rem;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.085rem;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: rgb(80, 129, 190);
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: flex-end;
  .btn {
    margin-left: 0.25rem;
  }
}

.tags_inputs {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
  .formInput {
    width: 30%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;
    }
    input {
      border: none;
      border-bottom: 1px solid #8d8d8d;
      outline: none;
      padding: 0.25rem;
      width: 100%;
      background: transparent;
      &:focus {
        border-color: #18a0fb;
      }
    }
    .err {
      border-color: red;
    }
  }
}
.synonyms_area {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  .title {
    padding: 0.5rem 0;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
  .add_btn {
    margin-left: auto;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    svg {
      height: 1rem;
      margin-right: 1rem;
    }
  }
  .table {
    thead {
      background-color: #f6f9fc;
      th {
        padding: 1rem;
        text-align: center !important;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .grey {
      background-color: #f2f2f2;
    }
    td {
      text-align: center !important;
      font-weight: 500;

      svg {
        height: 1rem;
        color: rgb(80, 129, 190);
        margin: 0 1rem;
      }
      input {
        height: 100%;
        width: 100%;
        padding: 1rem 0.5rem;
        font-size: 1rem;
        border: none;
        background: transparent;
      }
    }
  }
}
.settings {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
  .selectInput {
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    label {
      color: rgba(0, 0, 0, 0.54);
      text-transform: capitalize;
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 400;
    }
  }
  .formInput {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    label {
      color: rgba(0, 0, 0, 0.54);
      text-transform: capitalize;
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 400;
    }
    input {
      border: none;
      border-bottom: 1px solid #8d8d8d;
      outline: none;
      padding: 0.25rem;
      width: 100%;
      background: transparent;
      font-size: 1rem;
      &:focus {
        border-color: #18a0fb;
      }
    }
    .list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: #fff;
      z-index: 1;
      box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
      border: 1px solid rgba(80, 129, 190, 0.8);
      max-height: 300px;
      overflow-y: scroll;
      .loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      li {
        padding: 1rem 0.5rem;
        border-bottom: 1px solid rgba(80, 129, 190, 0.8);
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .synonymOf {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    label {
      color: rgba(0, 0, 0, 0.54);
      text-transform: capitalize;
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 400;
    }
    .flex {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border: 1px solid rgba(80, 129, 190, 0.8);
      width: fit-content;
      svg {
        height: 1rem;
        margin-left: 1rem;
      }
    }
  }
}
.extra_settings {
  margin-top: 1.5rem;

  .formInput {
    display: flex;
    align-items: center;
    font-size: 1rem;
    input {
      margin-right: 0.5rem;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
}
.bottom {
  margin-bottom: 0;
}
.nameErr {
  color: red;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
}

.errorBanner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #721c24;
  color: #721c24;
  background-color: #f8d7db;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  border-radius: 4px;
  font-size: 1rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  .themeSelect {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
    label {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .select_container {
    margin-top: 0.25rem;
    width: 100%;
  }
}

.associate {
  display: flex;
  align-items: center;
  margin: 1rem 0 -1rem;
  span {
    margin-right: 1rem;
  }
  .select_container {
    flex-grow: 1;
  }
}
