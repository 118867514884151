.homePage {
  padding: 2rem 8rem;
  padding-bottom: 8rem;
  position: relative;
}
.title {
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 2.813rem;
}
.actions_search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.actions {
  display: flex;
  align-items: center;
  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      color: rgb(80, 129, 190);
      background-color: transparent;
    }
  }
}
.btn {
  color: rgb(80, 129, 190);
  background-color: transparent;
  border: 1px solid rgba(80, 129, 190, 0.8);
  border-radius: 2px;
  height: 38px;
  font-size: 0.9rem;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.085rem;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: #fff;
    background-color: rgb(80, 129, 190);
  }
}
.search {
  height: 38px;
  width: 25rem;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  outline: none;
  padding: 0.25rem;
  font-size: 1.25rem;
  background: transparent;
  &:focus {
    border-color: #18a0fb;
  }
}
.filters_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .actions {
    background-color: #f6f9fc;
    margin-left: auto;
    display: flex;
    align-items: center;
    div {
      padding: 0.5rem 0.75rem 0;
      cursor: pointer;
    }
    svg {
      height: 1rem;
    }
    .close svg {
      height: 1.3rem;
    }
  }
}
.filters {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  background-color: #f6f9fc;
  padding: 1rem;

  .filter {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    &:last-child {
      margin-bottom: 0rem;
    }
    label,
    input {
      cursor: pointer;
    }
    input {
      margin-right: 0.25rem;
    }
  }
}
.table_data {
  display: flex;
  flex-direction: column;
  .limit {
    margin-left: auto;
    font-size: 1rem;
    width: 8rem;
    margin-bottom: 1rem;
  }
  .tags_table {
    thead {
      background-color: #f6f9fc;
      th {
        padding: 1rem 0rem;
        text-align: center !important;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    tbody tr:hover {
      background-color: rgba(80, 129, 190, 0.1);
    }
    .cell {
      padding: 0.75rem 0.5rem;
      text-align: center !important;
      font-weight: 500;
      .namesList li {
        &:nth-child(1) {
          color: #3a87ad;
        }
        &:nth-child(2) {
          color: #acc460;
        }
        &:nth-child(3) {
          color: #ff9900;
        }
      }
      svg {
        height: 1rem;
        color: rgb(80, 129, 190);
        margin: 0 0.5rem;
      }
    }
  }
}
.fetchingMask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff63;
  z-index: 9999;
}

.not_found {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
  font-weight: 500;
}

.pagination_container {
  position: relative;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .text_span {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  }
  .pagination {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    li {
      margin: 0 0.1rem;
      button {
        border: none;
        font-weight: 600;
        font-size: 14px;
        padding: 0.5rem 0.75rem;
        color: #3a87ad;
        background-color: transparent;
        letter-spacing: 0.085rem;
        transition: background-color 0.2s ease-out, color 0.2s ease-out;
        text-transform: uppercase;
        border-radius: 4px;
        &:hover {
          color: #fff;
          background-color: #3a87ad;
          box-shadow: 0px 0px 10px #d5d5d5;
        }
      }
      .active {
        color: #fff;
        background-color: #3a87ad;
        box-shadow: 0px 0px 10px #d5d5d5;
      }
      .disabled {
        opacity: 0.7;
        cursor: not-allowed;
        &:hover {
          color: #3a87ad;
          background: transparent;
          box-shadow: unset;
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}
.synonym_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  margin: auto;
  svg {
    color: rgb(80, 129, 190) !important;
    height: 1rem !important;
  }
}

.associate {
  display: flex;
  align-items: center;
  margin: 1rem 0 -1rem;
  span {
    margin-right: 1rem;
  }
  .select_container {
    width: 25%;
  }
  .btn {
    margin-left: 0.5rem;
  }
}
