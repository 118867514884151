.loginPage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.75rem;
  position: relative;
  font-weight: 300;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: rgba(60, 96, 142, 0.4);
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  h1 {
    font-size: 2.625rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    line-height: 200%;
    text-align: center !important;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    background-color: #375e8f;
    border: 1px solid #b9cde5;
    color: #fff;
    text-transform: uppercase;
    height: 40px;
    padding: 0 0.5rem;
    font-size: 12px;
    letter-spacing: 1.5px;
  }
}
