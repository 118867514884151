.modal {
  width: 60vw !important;
}

.content {
  width: 100%;
}

.table {
  thead {
    background-color: #f6f9fc;
    th {
      padding: 1rem 0rem;
      text-align: center !important;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  tbody tr:hover {
    background-color: rgba(80, 129, 190, 0.1);
  }
  .cell {
    padding: 0.75rem 0.5rem;
    text-align: center !important;
    font-weight: 500;
    .namesList li {
      &:nth-child(1) {
        color: #3a87ad;
      }
      &:nth-child(2) {
        color: #acc460;
      }
      &:nth-child(3) {
        color: #ff9900;
      }
    }
    svg {
      height: 1rem;
      color: rgb(80, 129, 190);
      margin: 0 0.5rem;
    }
  }
  tbody {
    display: block;
    max-height: 50vh;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.btn {
  color: rgb(80, 129, 190);
  background-color: transparent;
  border: 1px solid rgba(80, 129, 190, 0.8);
  border-radius: 2px;
  height: 38px;
  font-size: 0.9rem;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.085rem;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: rgb(80, 129, 190);
  }
}
.top_actions,
.bottom_actions {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      color: rgb(80, 129, 190);
      background-color: transparent;
    }
  }
  .active {
    color: #fff;
    background-color: rgb(80, 129, 190);
  }
}
.top_actions {
  .btn {
    margin-right: 0.5rem;
  }
}
.bottom_actions {
  margin-bottom: 0;
  justify-content: flex-end;
  .btn {
    margin-left: 0.5rem;
  }
}
.not_found {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
  font-weight: 500;
}
.fetchingMask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff63;
  z-index: 9999;
}
