.modal {
  width: 40vw !important;
}

.question {
  margin: 1rem 0;

  font-size: 1.25rem;
}
select {
  font-size: 1rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.inputForm {
  display: flex;
  align-items: center;
  font-size: 1rem;
  input {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  label {
    cursor: pointer;
  }
}
.actions {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    margin-left: 0.5rem;
    color: rgb(80, 129, 190);
    background-color: transparent;
    border: 1px solid rgba(80, 129, 190, 0.8);
    border-radius: 2px;
    height: 38px;
    font-size: 0.9rem;
    font-weight: 300;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.085rem;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    display: flex;
    align-items: center;

    &:hover {
      color: #fff;
      background-color: rgb(80, 129, 190);
    }
  }
}
