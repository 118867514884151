@import "tamtam-components/build/css/main.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$a-hover-color: #1468a0;

// Colors
$body-font-color: #29394d;

// Fonts
$body-font-family: "Roboto";

body {
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style-type: none;
    margin: 0;
  }

  button {
    cursor: pointer;
  }
}
