.layout {
  position: relative;
}

.header {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem 0 5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  svg {
    height: 40px;
    width: 40px;
  }
  .right {
    display: flex;
    align-items: center;
  }

  .languages {
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      color: #8d8d8d;
      text-transform: uppercase;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #8d8d8d;
      border-radius: 100%;
      padding: 0.5rem;
      font-size: 14px;
      height: 2rem;
      width: 2rem;
      &:hover {
        color: #29394d;
        border-color: #29394d;
      }
    }
    .active {
      color: #29394d;
      border-color: #29394d;
    }
  }

  .userAvatar {
    margin-left: 1.5rem;
    border-radius: 100%;
    position: relative;

    .avatar {
      border-radius: 100%;
      height: 3.25rem;
      width: 3.25rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #8d8d8d;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.5rem;
      letter-spacing: 1.5px;
    }
    .popup {
      display: none;
      position: absolute;
      z-index: 99;
      bottom: 0;
      right: 2%;
      transform: translateY(100%);
      padding: 1rem 0.75rem;
      height: max-content;
      width: max-content;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      .languages {
        padding: 1rem 0rem;
        margin: 1rem 0;
        justify-content: center;
        border-top: 1px solid #b2bcc6;
        border-bottom: 1px solid #b2bcc6;
      }
      .logout {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #18a0fb;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    &:hover {
      .popup {
        display: block;
      }
    }
  }
}
