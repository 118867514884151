.modal {
  width: 60vw !important;
}

.content {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
}

.table {
  width: 100%;
  thead {
    background-color: #f6f9fc;
    th {
      padding: 1rem 0rem;
      text-align: center !important;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  tbody tr:hover {
    background-color: rgba(80, 129, 190, 0.1);
  }
  .cell {
    padding: 0.75rem 0.5rem;
    text-align: center !important;
    font-weight: 500;
  }
}
